import { userConstants } from '../_constants/user.constants';

export function changePassword(state = {}, action) {
    switch (action.type) {
        case userConstants.CHANGE_PASSWORD_REQUEST:
            return { registering: true };
        case userConstants.CHANGE_PASSWORD_SUCCESS:
            return {};
        case userConstants.CHANGE_PASSWORD_FAILURE:
            return {};
        default:
            return state
    }
}

export default changePassword