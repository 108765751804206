import React, { useState, useEffect } from "react";
//import { HashRouter, Route, Switch } from 'react-router-dom';
//import React, { useEffect } from 'react';
import { Router, HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { history } from './_helpers/history';
import { alertActions } from './_actions/alert.actions';
import { PrivateRoute } from './_components/PrivateRoute';
import './scss/style.scss';
import './common.css'
import PropTypes from 'prop-types';
import Layout from './Layout'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const TheVendorLayout = React.lazy(() => import('./containers/TheVendorLayout'));

// Pages
const Login = React.lazy(() => import('./components/registration/Login'));
const ClientLogin = React.lazy(() => import('./components/registration/ClientLogin'));
const ForgotPassword = React.lazy(() => import('./components/registration/ForgotPassword'));
const ClientForgotPassword = React.lazy(() => import('./components/registration/ClientForgotPassword'));
const ResetPassword = React.lazy(() => import('./components/registration/ResetPassword'));
const Register = React.lazy(() => import('./components/registration/Registration'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const VendorStatus = React.lazy(() => import('./components/registration/VendorStatus'));

const App = () => {
    const alert = useSelector(state => state.alert);
    const dispatch = useDispatch();

    useEffect(() => {
        history.listen((location, action) => {
            // clear alert on location change
            dispatch(alertActions.clear());
        });
    }, []);

    return (
      <HashRouter>
          <React.Suspense fallback={loading}>
            {/*alert.message &&
                        <div className={`alert ${alert.type}`}>{alert.message}</div>
            */}
            <Router history={history}></Router>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/clientLogin" name="Client Login Page" render={props => <ClientLogin {...props}/>} />
              <Route exact path="/forgot-password" name="Forgot Password" render={props => <ForgotPassword {...props}/>} />
              <Route exact path="/client-forgot-password" name="Client Forgot Password" render={props => <ClientForgotPassword {...props}/>} />
              <Route exact path="/reset-password" name="reset Password" render={props => <ResetPassword {...props}/>} />
              <Route exact path="/vendor-status" name="Vendor Status" render={props => <VendorStatus {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              {/*<Route path='/' render={(props) => <Layout {...props} /> } />*/}
              <Route path="/" name="vendor" render={props => <TheLayout {...props}/>} />
              {/*<Route exact path="/admin-dashboard" name="admin" render={props => <TheVendorLayout {...props}/>} />*/}
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
}

App.propTypes = {
  match: PropTypes.any.isRequired,
  history: PropTypes.func.isRequired
}

export default App;
