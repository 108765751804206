import { combineReducers } from "redux";
import alert from "./alert.reducers";
import authentication from "./authentication.reducers";
import registration from "./registration.reducers";
import users from "./users.reducers"
import changePassword from "./changePassword.reducers"
import addUser from "./addUser.reducers"
import editUser from "./editUser.reducers"
import addInvoice from "./addInvoice.reducers"
import editInvoice from "./editInvoice.reducers"
import clientAddUser from "./clientAddUser.reducers"
import clients from "./clients.reducers"
import vendorContactEdit from "./vendorContactEdit.reducers"
import editVendor from "./editVendor.reducers"
import clientLogin from "./clientLogin.reducers"

export default combineReducers({
  addInvoice,
  addUser,
  alert,
  clients,
  clientAddUser,
  clientLogin,
  editInvoice,
  editUser,
  authentication,
  users,
  registration,
  changePassword,
  vendorContactEdit,
  editVendor,
});