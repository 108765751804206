export const logoNegative = ['608 134', `
  <title>QualApps</title>
  <g transform="translate(0.000000,153.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M2060 1101 c-83 -26 -159 -90 -201 -171 -21 -39 -24 -59 -24 -145 0
-89 3 -105 27 -151 36 -68 113 -136 178 -157 65 -21 154 -22 211 -2 l44 16 25
-31 25 -30 29 23 30 24 -22 30 -21 30 25 24 c13 12 37 48 52 78 23 49 27 68
27 146 0 75 -4 98 -25 140 -35 72 -105 138 -173 164 -59 21 -156 27 -207 12z
m202 -98 c93 -51 141 -160 119 -270 -11 -54 -45 -123 -61 -123 -4 0 -22 23
-40 50 -18 28 -34 50 -37 50 -3 0 -18 -9 -34 -21 l-29 -20 35 -52 c23 -34 31
-55 25 -61 -17 -17 -142 -20 -180 -4 -50 21 -98 64 -123 112 -31 58 -31 184 0
242 44 82 114 123 212 124 50 0 73 -5 113 -27z"/>
<path d="M3470 785 l0 -315 40 0 40 0 0 315 0 315 -40 0 -40 0 0 -315z"/>
<path d="M3731 798 c-63 -167 -116 -309 -119 -315 -3 -9 9 -13 41 -13 l45 0
28 83 29 82 134 0 133 0 30 -82 30 -83 44 0 c24 0 44 1 44 3 0 1 -53 142 -118
312 l-117 310 -45 3 -46 3 -113 -303z m207 72 c24 -67 45 -127 49 -135 4 -13
-12 -15 -97 -15 -77 0 -101 3 -98 13 53 150 93 257 98 257 3 0 24 -54 48 -120z"/>
<path d="M3142 908 c-61 -10 -122 -58 -122 -95 0 -5 15 -14 32 -21 29 -11 35
-10 48 7 60 75 200 37 200 -55 0 -12 -5 -13 -22 -7 -13 4 -57 8 -98 8 -61 0
-82 -4 -113 -24 -127 -78 -66 -261 87 -261 57 0 90 13 121 46 l25 27 0 -32 c0
-30 2 -31 40 -31 l40 0 0 143 c0 78 -5 158 -10 179 -9 32 -76 107 -98 108 -4
1 -25 4 -47 8 -22 5 -59 5 -83 0z m139 -238 c24 -13 24 -33 2 -72 -19 -32 -79
-68 -113 -68 -29 0 -76 26 -84 45 -11 30 -6 46 23 76 26 26 36 29 91 29 34 0
70 -5 81 -10z"/>
<path d="M4407 905 c-26 -7 -59 -23 -72 -35 l-25 -23 0 26 c0 25 -3 27 -40 27
l-40 0 0 -310 0 -310 40 0 40 0 0 122 c0 114 1 120 18 106 132 -117 332 -9
332 179 0 78 -51 171 -111 203 -38 21 -96 27 -142 15z m100 -85 c41 -24 63
-59 70 -107 16 -123 -85 -215 -184 -167 -54 27 -83 75 -83 138 0 121 104 193
197 136z"/>
<path d="M4903 908 c-23 -5 -56 -22 -72 -37 l-31 -29 0 29 c0 27 -3 29 -40 29
l-40 0 2 -307 3 -308 38 -3 37 -3 0 127 0 128 28 -26 c70 -68 176 -65 251 5
48 46 71 101 71 173 0 147 -113 249 -247 222z m91 -83 c71 -34 100 -156 53
-226 -79 -120 -247 -62 -247 85 0 116 98 186 194 141z"/>
<path d="M5332 909 c-46 -9 -96 -39 -112 -69 -6 -10 -10 -36 -10 -57 0 -64 54
-104 178 -133 62 -14 91 -45 72 -79 -14 -27 -41 -41 -80 -41 -31 0 -90 24 -90
38 0 4 -7 15 -14 24 -11 15 -17 16 -44 6 -18 -7 -32 -18 -32 -25 0 -21 63 -85
97 -99 45 -19 131 -17 173 4 40 20 80 74 80 109 0 72 -53 117 -170 143 -30 7
-63 19 -72 28 -69 59 70 116 140 57 26 -22 33 -23 61 -14 36 13 38 18 11 51
-37 48 -114 71 -188 57z"/>
<path d="M2550 738 c0 -150 2 -166 23 -203 27 -48 76 -75 135 -75 49 0 75 11
115 48 l27 26 0 -32 c0 -31 2 -32 40 -32 l40 0 0 215 0 215 -45 0 -45 0 0
-140 c0 -157 -9 -189 -60 -215 -36 -19 -63 -19 -99 -1 -44 23 -51 53 -51 211
l0 145 -40 0 -40 0 0 -162z"/>
<path d="M330 689 c0 -77 32 -196 77 -282 44 -86 177 -224 265 -276 74 -43
203 -87 298 -102 331 -51 684 135 790 418 11 31 19 57 17 59 -2 2 -21 -21 -42
-52 -84 -122 -253 -234 -405 -268 -291 -67 -611 18 -809 214 -79 78 -126 155
-163 265 l-28 80 0 -56z"/>
</g>
</svg>
`]
