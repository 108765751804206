import { userConstants } from '../_constants/user.constants';

export function editUser(state = {}, action) {
    switch (action.type) {
        case userConstants.REGISTER_REQUEST:
            return { update: true };
        case userConstants.REGISTER_SUCCESS:
            return {};
        case userConstants.REGISTER_FAILURE:
            return {};
        default:
            return state
    }
}

export default editUser