import { userConstants } from '../_constants/user.constants';

export function addUser(state = {}, action) {
    switch (action.type) {
        case userConstants.REGISTER_REQUEST:
            return { registering: true };
        case userConstants.REGISTER_SUCCESS:
            return { 
                successMessage: true
            };
        case userConstants.REGISTER_FAILURE:
            return {
                errorMessage: true
            };
        default:
            return state
    }
}

export default addUser

/*const reducer = (state = {}, action) => {
    switch (action.type) {
      case REDIRECT:
        return { redirectTo: action.payload };
      default:
        return state;
    }
  };
  
  export default reducer;*/