import { userConstants } from '../_constants/user.constants';
import { getStorage } from "../_actions/storage.actions"

let user = getStorage("client", "token");//JSON.parse(localStorage.getItem('client'));
const initialState = user ? { loggedIn: true, user } : {};

export function clientLogin(state = initialState, action) {
    switch (action.type) {
        case userConstants.CLIENT_LOGIN_REQUEST:
            return {
                loggingIn: true,
                user: action.user
            };
        case userConstants.CLIENT_LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.user
            };
        case userConstants.CLIENT_LOGIN_FAILURE:
            return {
                errorMessage: true
            };
        case userConstants.LOGOUT:
            return {};
        default:
            return state
    }
}

export default clientLogin