export const getStorage = (loginType, key) => {
  let userObject = localStorage.getItem(loginType);
  return userObject && JSON.parse(userObject)[key];
};

export const setStorage = (loginType, key, value) => {
  let userObject = localStorage.getItem(loginType);
  if (userObject) {
    userObject = JSON.parse(userObject);
  } else {
    userObject = {};
  }
  userObject[key] = value;
  localStorage.setItem(loginType, JSON.stringify(userObject));
};

export const clearStore = (loginType) => {
  localStorage.removeItem(loginType);
};
